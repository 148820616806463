const FAMILY_G = "G",
  FAMILY_S = "S",
  FAMILY_Q = "Q";

import { PAUSE, PRODUCTION } from "../context/eventContext";

export const classNames = function (...classes) {
  return classes.filter(Boolean).join(" ");
};

export const requestSubmit = (form) => {
  const fakeButton = document.createElement("button");
  fakeButton.type = "submit";
  fakeButton.style.display = "none";
  form.appendChild(fakeButton);
  fakeButton.click();
  fakeButton.remove();
};

export const formatSecondDurationToTimeString = (
  duration,
  withSecond = true
) => {
  let minutes = null;
  let seconds = null;

  if (!!duration) {
    minutes = Math.floor(duration / 60);
    seconds = duration - minutes * 60;

    return withSecond
      ? `${minutes < 10 ? "0" + minutes.toString() : minutes}:${
          seconds < 10 ? "0" + seconds.toString() : seconds
        }`
      : minutes.toString();
  } else if (duration === 0 || duration === "0") {
    return withSecond ? "00:00" : 0;
  }

  return null;
};

export const computeIndicators = (
  events,
  instruction,
  pauseReasons,
  firstPlay
) => {
  let totalConform = 0;
  let totalDefault = 0;
  let totalProductionDuration = 0;
  let totalPauseG = 0;

  events.forEach((event, index) => {
    if (event.type === PRODUCTION && event.duration) {
      ++totalConform;
      totalProductionDuration += event.duration;
    }

    if (event.type === PAUSE) {
      let familyG = event.properties.productionPauseReasons.some(
        (pauseReasonId) => {
          return (
            pauseReasons.find(
              (pauseReason) =>
                parseInt(pauseReason.id, 10) === parseInt(pauseReasonId, 10)
            ).family === FAMILY_G
          );
        }
      );

      if (familyG) {
        totalPauseG += event.duration;
      }

      let familyQ = event.properties.productionPauseReasons.some(
        (pauseReasonId) => {
          return (
            pauseReasons.find(
              (pauseReason) =>
                parseInt(pauseReason.id, 10) === parseInt(pauseReasonId, 10)
            ).family === FAMILY_Q
          );
        }
      );

      if (familyQ) {
        ++totalDefault;
      }
    }
  });

  let trsCompute =
    ((instruction.cycleDuration * totalConform) /
      (totalProductionDuration - totalPauseG)) *
    100;
  return {
    trs: trsCompute ? trsCompute : 0,
    totalConform: totalConform,
    totalDefault: totalDefault,
  };
};
