import { Navbar } from "./navbar";
import React, { useContext } from "react";
import { authContext } from "../context/authContext";
import { GlobalDialog } from "./Dialog";

export const Layout = ({ children }) => {
  const { user } = useContext(authContext);
  return (
    <div className="h-screen bg-gray-50 flex flex-col">
      <Navbar />
      <main className="w-full p-4 flex-1 flex flex-col">
        {user.session ? children : "Aucune session active"}
      </main>
      <GlobalDialog />
    </div>
  );
};
