import React, { createContext, useContext, useEffect, useState } from 'react';
import { DELETE, POST, useApi } from '../hook/useApi';
import { computeIndicators } from "../utils/misc";
import {authContext} from "./authContext";

export const eventContext = createContext({});
export const PRODUCTION = "production";
export const PAUSE = "pause";
export const PLAY = "play";
export const STOP = "stop";

const EventProvider = ({ children }) => {
  const {user} = useContext(authContext);
  const [firstPlay, setFirstPlay] = useState(null);
  const [events, setEvents] = useState([]);
  const [addEventCall, setAddEventCall] = useState(false); // init default
  const [pauseReasons, setPauseReasons] = useState([]);
  const [instruction, setInstruction] = useState(null);
  const [indicators, setIndicators] = useState({
    trs: 0,
    totalConform: 0,
    default: 0,
  });
  const { error, callFetch, data, loading } = useApi("production-event-logs");

  const [active, setActive] = useState(null);

  useEffect(() => {
    if(user && user.session === null){
      reset();
    }
  }, [user]);

  // update event id after api return
  useEffect(() => {
    if(data && !error){
      let _events = events;
      let eventIndexNoId = events.findIndex((event) => !event.id);
      _events[eventIndexNoId] = {id: data.id, ...events[eventIndexNoId]};
      setAddEventCall(false);
      setEvents([..._events]);
    }
  }, [data, error])

  // event structure {type: null, createdAt: null, duration: null, properties: null}
  const addEvent = (event) => {
    events.push(event);
    setEvents([...events]);
    setAddEventCall(true);
  };

  useEffect(() => {
    let lastEvent = events.slice(-1)[0];
    let beforeLastEvent = events.slice(-2)[0];
    if (!!beforeLastEvent && beforeLastEvent.type === STOP) {
      // not take in consideration only PAUSE, PRODUCTION OR PLAY
      beforeLastEvent = events.slice(-3)[0];
    }
    if (lastEvent && addEventCall) {
      switch (lastEvent.type) {
        case PLAY:
          sendPlayEvent(lastEvent);
          break;
        case PRODUCTION:
          sendProductionEvent(lastEvent, beforeLastEvent);
          break;
        case PAUSE:
          sendPauseEvent(lastEvent, beforeLastEvent);
          break;
        case STOP:
          sendStopEvent(lastEvent);
          break;
      }
    }
  }, [events.length, addEventCall]);

  useEffect(() => {
    if (error) {
      console.log("Something wrong happen : " + error);
    }
  }, [error]);

  useEffect(() => {
    if (events.length > 0) {
      let lastEvent = events.slice(-1)[0];
      if (lastEvent.type === PRODUCTION || lastEvent.type === PAUSE) {
        setActive(PLAY);
      } else {
        setActive(lastEvent.type);
      }
    }
  }, [events.length]);

  useEffect(() => {
    if (
      events.length > 0 &&
      instruction &&
      pauseReasons.length > 0 &&
      firstPlay
    ) {
      setIndicators(
        computeIndicators(events, instruction, pauseReasons, firstPlay)
      );
    }
  }, [JSON.stringify(events), instruction, pauseReasons.length, firstPlay]);

  const sendPlayEvent = (event) => {
    if (firstPlay === null) {
      setFirstPlay(event.createdAt);
    }

    callFetch({
      method: POST,
      body: {
        ...event,
        createdAt: new Date(event.createdAt).toLocaleTimeString(),
      },
    });
  };

  const sendProductionEvent = (event, beforeLastEvent) => {
    event.duration = Math.floor(
      (new Date(event.createdAt).getTime() -
        new Date(beforeLastEvent.createdAt).getTime()) /
        1000
    );
    callFetch({
      method: POST,
      body: {
        ...event,
        createdAt: new Date(event.createdAt).toLocaleTimeString(),
      },
    });
    setEvents([...events]);
  };

  const sendPauseEvent = (event, beforeLastEvent) => {
    event.duration = Math.floor(
      (new Date().getTime() - new Date(beforeLastEvent.createdAt).getTime()) /
        1000
    );
    callFetch({
      method: POST,
      body: {
        ...event,
        createdAt: new Date(event.createdAt).toLocaleTimeString(),
      },
    });
    setEvents([...events]);
  };

  const sendStopEvent = (event) => {
    callFetch({
      method: POST,
      body: {
        ...event,
        createdAt: new Date(event.createdAt).toLocaleTimeString(),
      },
    });
  };

  const reset = () => {
    setFirstPlay(null);
    setEvents([]);
    setAddEventCall(false);
    setPauseReasons([]);
    setInstruction(null);
    setIndicators({
      trs: 0,
      totalConform: 0,
      default: 0,
    });
    setActive(null);
  }

  return (
    <eventContext.Provider
      value={{
        setEvents,
        addEvent,
        firstPlay,
        setFirstPlay,
        events,
        setPauseReasons,
        pauseReasons,
        instruction,
        setInstruction,
        indicators,
        setActive,
        active,
        loading
      }}
    >
      {children}
    </eventContext.Provider>
  );
};

export default EventProvider;
