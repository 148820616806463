import "../styles/style.css";
import AuthProvider from "../context/authContext";
import { useRouter } from "next/router";
import SecureRoute from "../security/secureRoute";
import EventProvider from "../context/eventContext";
import Head from "next/head";
import ModalProvider from "../context/modalContext";
import ImprovementProvider from "../context/improvementContext";
import React, { useEffect } from 'react'

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(async () => {
    try {
      await navigator.wakeLock.request('screen');
    } catch (err) {
      // the wake lock request fails - usually system related, such being low on battery
      console.log(`${err.name}, ${err.message}`);
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#f6A925" />
        <link rel="apple-touch-icon" href="/icon-96x96.png" />
        <meta name="apple-mobile-web-app-status-bar" content="#f6A925" />
      </Head>
      <AuthProvider>
        {!router.asPath.includes("/login") ? (
          <ModalProvider>
            <EventProvider>
              <ImprovementProvider>
                <SecureRoute Component={Component} pageProps={pageProps} />
              </ImprovementProvider>
            </EventProvider>
          </ModalProvider>
        ) : (
          <Component {...pageProps} />
        )}
      </AuthProvider>
    </>
  );
}

export default MyApp;
