import React, { useContext, useEffect, useState } from "react";
import { authContext } from "../context/authContext";

const withAuth = (WrappedComponent) => {
  return (props) => {
    if (typeof window !== "undefined") {
      // process client side no ssr
      const { isAuthenticated, loading } = useContext(authContext);
      const [authenticated, setAuthenticated] = useState(false);

      useEffect(async () => {
        let authenticated = await isAuthenticated();
        setAuthenticated(authenticated);
        return () => setAuthenticated(false);
      }, []);

      return !loading && authenticated ? <WrappedComponent {...props} /> : null;
    }

    return null;
  };
};

export default withAuth;
