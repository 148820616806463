import React, { useContext } from "react";
import { Layout } from "../components/layout";
import withAuth from "./withAuth";
import { authContext } from "../context/authContext";
import { useRouter } from "next/router";

const SecureRoute = ({ Component, pageProps }) => {
  const router = useRouter();

  if (!checkRoleAccess()) {
    router.push("403");
  } else {
    return (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    );
  }

  return <Layout />;
};

function checkRoleAccess() {
  const { user } = useContext(authContext);
  let allowed = true;
  const router = useRouter();
  if (router.pathname === "/" && !user.roles.includes("ROLE_OPERATOR")) {
    allowed = false;
  }
  if (
    router.pathname.startsWith("/improvements") &&
    !user.roles.includes("ROLE_OPERATOR") &&
    !user.roles.includes("ROLE_MANAGER") &&
    !user.roles.includes("ROLE_TECHNICIAN")
  ) {
    allowed = false;
  }
  if (
    router.pathname.startsWith("/formations") &&
    !user.roles.includes("ROLE_OPERATOR")
  ) {
    allowed = false;
  }
  if (
    router.pathname.startsWith("/documents") &&
    !user.roles.includes("ROLE_OPERATOR")
  ) {
    allowed = false;
  }

  if (
    router.pathname.startsWith("/performances") &&
    !user.roles.includes("ROLE_MANAGER")
  ) {
    return false;
  }
  return allowed;
}

export default withAuth(SecureRoute);
