import React, { createContext, useState } from "react";

export const modalContext = createContext({});

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);

  return (
    <modalContext.Provider value={{ setOpen, setContent, open, content }}>
      {children}
    </modalContext.Provider>
  );
};

export default ModalProvider;
