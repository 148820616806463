import React, { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { classNames } from "../utils/misc";
import Link from "next/link";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { authContext } from "../context/authContext";
import { useRouter } from "next/router";
import { LogoutIcon } from "@heroicons/react/outline";

const navigation = [
  {
    name: "Production",
    href: "/",
    roles_denied: ["ROLE_MANAGER", "ROLE_TECHNICIAN"],
  },
  { name: "Amélioration QRQC", href: "/improvements", roles_denied: [] },
  {
    name: "Performances",
    href: "/performances",
    roles_denied: ["ROLE_OPERATOR", "ROLE_TECHNICIAN"],
  },
  {
    name: "Formations",
    href: "/formations",
    roles_denied: ["ROLE_MANAGER", "ROLE_TECHNICIAN"],
  },
  {
    name: "Documents",
    href: "/documents",
    roles_denied: ["ROLE_MANAGER", "ROLE_TECHNICIAN"],
  },
];

export const Navbar = () => {
  const { logout, user } = useContext(authContext);
  const router = useRouter();

  return (
    <div className="bg-gray-50 flex-initial">
      <Disclosure
        as="nav"
        className="border-border-gray-300 border-opacity-25 bg-white lg:border-none"
      >
        {({ open, close }) => (
          <>
            <div className="w-full p-4">
              <div className="relative flex items-center justify-between z-50">
                <div className="flex items-center px-2 lg:px-0">
                  <div className="flex-shrink-0 rounded bg-white p-2">
                    <Link href={user && user.roles.includes('ROLE_MANAGER') ? '/performances' : '/'}>
                      <a>
                        <img
                          className="block w-16"
                          src="/logo.png"
                          alt="logo"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="hidden lg:ml-10 lg:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) =>
                        item.roles_denied.some((v) =>
                          user.roles.includes(v)
                        ) ? (
                          ""
                        ) : (
                          <Link key={item.name} href={item.href}>
                            <a
                              className={classNames(
                                (router.asPath.search(item.href) !== -1 &&
                                  item.href !== "/") ||
                                  (item.href === "/" &&
                                    item.href === router.asPath)
                                  ? "btn-primary"
                                  : "text-gray-600 hover:bg-trames-orange hover:bg-opacity-10 hover:text-trames-orange",
                                "btn focus:ring-trames-orange"
                              )}
                              aria-current={
                                item.href === router.asPath ? "page" : undefined
                              }
                            >
                              {item.name}
                            </a>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center lg:hidden">
                  <div className="grid grid-rows-2 mr-4">
                    <img
                        className="block w-14 m-auto row-span-2 mr-4"
                        src="/avatar.svg"
                        alt="logo"
                    />
                    <p className="col-start-2"><strong>{user.username}</strong></p>
                    <div className="row-start-2 col-start-2 text-sm flex items-center cursor-pointer hover:underline" onClick={() => logout()}>
                      <LogoutIcon className="mr-1 h-5 w-5 inline-block" />
                      <p>Déconnexion</p>
                    </div>
                  </div>
                  {/* Mobile menu button */}
                  <Disclosure.Button className="z-50 inline-flex items-center justify-center rounded-md bg-trames-orange bg-opacity-20 p-2 text-trames-orange">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden lg:ml-4 lg:block">
                  <div className="grid grid-rows-2">
                    <img
                        className="block w-14 m-auto row-span-2 mr-4"
                        src="/avatar.svg"
                        alt="logo"
                    />
                    <p className="col-start-2"><strong>{user.username}</strong></p>
                    <div className="row-start-2 col-start-2 text-sm flex items-center cursor-pointer hover:underline" onClick={() => logout()}>
                      <LogoutIcon className="mr-1 h-5 w-5 inline-block" />
                      <p>Déconnexion</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="relative z-50 lg:hidden">
              <div className="space-y-2 px-2 pt-2 pb-3">
                {navigation.map((item) =>
                  item.roles_denied.some((v) => user.roles.includes(v)) ? (
                    ""
                  ) : (
                    <Link key={item.name} href={item.href}>
                      <a
                        className={classNames(
                          (router.asPath.search(item.href) !== -1 &&
                            item.href !== "/") ||
                            (item.href === "/" && item.href === router.asPath)
                            ? "btn-primary"
                            : "text-gray-600 hover:bg-trames-orange hover:bg-opacity-10 hover:text-trames-orange",
                          "btn block"
                        )}
                        onClick={() => close()}
                        aria-current={
                          item.href === router.asPath ? "page" : undefined
                        }
                      >
                        {item.name}
                      </a>
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
