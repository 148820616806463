import React, { useCallback, useContext, useState } from "react";
import { authContext } from "../context/authContext";

export const GET = "GET";
export const PATCH = "PATCH";
export const POST = "POST";
export const DELETE = "DELETE";

export const useApi = (url) => {
  const { getToken } = useContext(authContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const controller = new AbortController();
  const signal = controller.signal;

  const callFetch = useCallback(
    (options) => {
      setError(null);
      setLoading(true);

      let token = getToken();

      fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/${url}${
          options.id ? `/${options.id}` : ""
        }${options.param ? `?${options.param}` : ""}`,
        {
          method: options.method,
          headers: {
            ...(options.body instanceof FormData
              ? {}
              : { "Content-Type": "application/json" }),
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
          ...(options.body
            ? {
                body: !(options.body instanceof FormData)
                  ? JSON.stringify(options.body)
                  : options.body,
              }
            : {}),
          signal: signal,
        }
      )
        .then((res) => {
          let status = res.status;
          if (status === 200 || status === 201) {
            return !options.blob ? res.json() : res.blob();
          } else {
            throw res;
          }
        })
        .then(
          (result) => {
            setLoading(false);
            setData(result);
          },
          (error) => {
            if (error instanceof TypeError) {
              //no network or cors
              setError(error.toString());
            } else if (error instanceof Response) {
              if (error.status === 401) {
                setError("Identifiants invalides");
              } else {
                setError({ message: error.statusText, code: error.status });
              }
            }
            setLoading(false);
          }
        );
    },
    [url]
  );

  const cancelFetch = () => {
    controller.abort();
  };

  return { data, loading, error, callFetch, cancelFetch };
};
