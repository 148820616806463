export const refreshTokenFetch = async (refreshToken) => {
  let response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/token/refresh`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    }
  );

  try {
    if (response.status === 200) {
      let json = await response.json();
      if (json.token) {
        return { token: json.token, refreshToken: json.refresh_token };
      }
    }
    throw response;
  } catch (e) {
    console.log("Something wrong happen during refresh token");
  }
  return null;
};
