import React, { createContext, useState } from "react";

export const improvementContext = createContext({});

export const OWNED = "owned";
export const TEAM = "team";

const ImprovementProvider = ({ children }) => {
  const [type, setType] = useState(OWNED);

  return (
    <improvementContext.Provider value={{ type, setType }}>
      {children}
    </improvementContext.Provider>
  );
};

export default ImprovementProvider;
