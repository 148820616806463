import React, { createContext, useEffect, useRef, useState } from "react";
import { refreshTokenFetch } from "../utils/login";
import { useRouter } from "next/router";
import jwt_decode from "jwt-decode";

export const tokenIsValid = (token) => {
  return jwt_decode(token).exp > Date.now().valueOf() / 1000;
};

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const router = useRouter();
  const refreshTimeOut = useRef(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let token = getToken();
    if (token !== null && tokenIsValid(token)) {
      refreshTokenCall();
    }
    return () => {
      abordRefreshToken();
    };
  }, []);

  const refreshTokenCall = () => {
    let token = getToken();

    refreshTimeOut.current = window.setTimeout(async () => {
      await fetchRefresh();
    }, (jwt_decode(token).exp - Date.now().valueOf() / 1000) * 1000 - 5000); // Validity period of the token in seconds, minus 5 seconds
  };

  const fetchRefresh = async () => {
    let response = await refreshTokenFetch(getRefreshToken());
    if (response !== null) {
      setAuthData(response.token, response.refreshToken);
      return true;
    } else {
      abordRefreshToken();
      redirectToLogin();
    }
  };

  const redirectToLogin = (redirect = null) => {
    let route = "/login";
    if (redirect !== null) {
      route = {
        pathname: "/login",
        query: { redirect: redirect },
      };
    }
    router.replace(route);
    return null;
  };

  const abordRefreshToken = () => {
    if (refreshTimeOut.current) {
      window.clearTimeout(refreshTimeOut.current);
    }
  };

  const setAuthData = (token, refreshToken) => {
    window.localStorage.setItem(
      "token",
      token !== null ? JSON.stringify(token) : null
    );
    window.localStorage.setItem(
      "refreshToken",
      refreshToken !== null ? JSON.stringify(refreshToken) : null
    );

    if (token !== null && refreshToken !== null) {
      setUser(defineUser(token));
      refreshTokenCall(); //begin timeout in order to refresh in time
    }
  };

  const isAuthenticated = async () => {
    let previousRoute = router.asPath;
    let token = getToken();
    if (token !== null && !tokenIsValid(token)) {
      setLoading(true);
      let success = await fetchRefresh(); //try to refresh, if it's impossible redirect to login
      setLoading(false);

      return success;
    } else if (token === null) {
      setLoading(false);
      redirectToLogin(previousRoute);
    } else if (tokenIsValid(token)) {
      setUser(defineUser(token));
      setLoading(false);
      return true;
    } else {
      setLoading(false);
      redirectToLogin(previousRoute);
    }
  };

  const logout = () => {
    abordRefreshToken();
    setAuthData(null, null);
    router.replace("/login");

    return null;
  };

  const getToken = () => {
    return window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "undefined"
      ? JSON.parse(window.localStorage.getItem("token"))
      : null;
  };

  const getRefreshToken = () => {
    return window.localStorage.getItem("refreshToken") !== null &&
      window.localStorage.getItem("refreshToken") !== "undefined"
      ? JSON.parse(window.localStorage.getItem("refreshToken"))
      : null;
  };

  const defineUser = (token) => {
    if (token !== null) {
      let decode = jwt_decode(token);
      return {
        uid: decode["uid"],
        username: decode["username"],
        production_order_number: decode["production_order_number"],
        roles: decode["roles"],
        session: decode["session"],
      };
    }

    return null;
  };

  return (
    <authContext.Provider
      value={{ setAuthData, isAuthenticated, logout, getToken, loading, user }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
